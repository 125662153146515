/**
 * Initializes the application modules based on the configuration.
 *
 * This function iterates through the `MODULES` configuration,
 * which is an array of objects with `module` and `param` properties.
 * For each module, it checks if the corresponding
 * configuration parameter is truthy or set to `'NO_PARAM'`.
 * Configuration is obtained from store config trought api call (/api/v1/posweb/global_config)
 * If the condition is met, it dynamically imports the module
 * and calls its `init` function, passing the `app` instance.
 *
 * This function is responsible for bootstrapping the application's modules and ensuring
 * they are properly initialized based on the configuration.
 * es:
 *  param: 'ENDLESSAISLE_ENABLED',
    module: 'endless-aisle',
 */
import { MODULES } from '@/configs'
import { app } from '@/main'
import { FeLogger } from '@/addons/monitoring'
// import { checkLegacyModuleConfig } from '@/abs-core/shared/lib/plugin-system'
// import { GetStoreConfigDataAttributes } from '@/api'

export function initLegacyModules() {
  MODULES.forEach(async (item) => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars, unused-imports/no-unused-vars
      const { module, param } = item
      // TODO restore config checks on modules - removed because the modules were
      // not correctly loaded after login
      // if (
      //   checkLegacyModuleConfig(param as keyof GetStoreConfigDataAttributes)
      // ) {
      const moduleImport = await import(`@/modules/${module}/index.ts`)
      await moduleImport.init(app)
      // }
    } catch (err) {
      FeLogger.error(
        `[InitLegacyModules] Error initializing module: ${item.module}`,
        err,
      )
    }
  })
}
